<template>
  <div class="title_component">
    <div class="bar_one"></div>
    <div class="bar_two"></div>
    <div class="title">{{title}}</div>
    <div class="bar_two"></div>
    <div class="bar_one"></div>
    <!-- <div class="text">{{titleEnglish}}</div> -->
  </div>
</template>

<script>
export default {
  name: "titleComponent",
  props: {
    title: {
      type: String,
      default: ''
    },
    titleEnglish: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.title_component{
  width: 100%;
  @include flex();
  color: #228ded;
  margin-bottom: 60px;
  .title{
    font-weight: bold;
    padding: 0 10px;
    font-size: 39px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #3565B5;
    line-height: 50px;
  }
  .bar_one{
    width: 4px;
    height: 19px;
    background: #3565B5;
  }
  .bar_two{
    width: 4px;
    height: 24px;
    margin: 0 5px;
    background: #3565B5;
  }
  .text{
    font-size: 24px;
    font-weight: 700;
    padding: 0 20px;
    line-height: 30px;
  }
}
</style>
