<template>
  <div class="train_page">
    <div class="navigate">
      <div class="item tab" @click="toTrainCategory">
        <span class="icon base"></span>
        <div>基础培训</div>
      </div>
<!--      //$router.push({path:'/trainOnline'})-->
      <div class="item tab" @click="$message.success('敬请期待')">
      <!-- <div class="item tab" @click="toJump('/trainOnline')" > -->
        <span class="icon online"></span>
        <div>线上培训</div>
      </div>
      <div class="item tab" @click="$message.success('敬请期待')">
        <span class="icon special"></span>
        <div>专项培训</div>
      </div>
    </div>
    <div class="top">
      <div class="info">
        <b>| 权威发布 | </b>
        <div>全国首届幼儿足球师资国家级<br />专项（示范） 培训班开班</div>
        <a @click="$router.push({path:'/newsDetail',query:{id:11}})">查看更多</a>
      </div>
      <div
        class="pic"
        :style="{
          'background-image':
            'url(https://oss.prefootball.cn/uploads/20190905/08ecc6984d3195b2d7c85abdcf871408.jpg)',
        }"
      ></div>
    </div>

    <titleComponent title="教师风采" titleEnglish="ABOUT TEACHERS" />

    <div class="haspic">
      <div class="imgs">
        <div
          class="pic"
          style="background-image: url(https://oss.prefootball.cn/uploads/20200221/11dd1aa3b6f8c9efde4b43927e1b154c.jpg);"></div>
        <div
          class="pic"
          style="background-image: url(https://oss.prefootball.cn/uploads/20200221/39e979da3ae0dac5dd07f4fd956e393e.jpg);"
        ></div>
      </div>
      <div class="info">
        <b>教师风采</b>
        <div>跟随外籍专业教练<br />成就自我</div>
      </div>
    </div>

    <div class="haspic">
      <div class="info">
        <b>教师风采</b>
        <div>在运动中燃烧<br />对教育事业的热情</div>
      </div>
      <div class="imgs">
        <div
          class="pic"
          style="background-image: url(https://oss.prefootball.cn/uploads/20200221/90ad5e18912b5dc67352f637e44ea376.jpg);"
        ></div>
        <div
          class="pic"
          style="background-image: url(https://oss.prefootball.cn/uploads/20200221/62d3799115cea589446317455f104950.jpg);"
        ></div>
      </div>
    </div>

    <div class="haspic">
      <div class="imgs">
        <div
          class="pic"
          style="background-image: url(https://oss.prefootball.cn/uploads/20200221/77927a82cf881ef8d29bed16b6f392d4.jpg);"
        ></div>
        <div
          class="pic"
          style="background-image: url(https://oss.prefootball.cn/uploads/20200221/c567b27b461cc3c28c19f50eb35c1620.jpg);"
        ></div>
      </div>
      <div class="info">
        <b>教师风采</b>
        <div>在训练中<br />尽情挥洒汗水</div>
      </div>
    </div>

    <div class="haspic">
      <div class="info">
        <b>教师风采</b>
        <div>参与会议</div>
      </div>
      <div class="imgs">
        <div
          class="pic"
          style="background-image: url(https://oss.prefootball.cn/uploads/20190803/5aa883c6bd9dc940f8e45b3b674dc8ea.jpg);"
        ></div>
        <div
          class="pic"
          style="background-image: url(https://oss.prefootball.cn/uploads/20200221/a0b982bf86d286e64fe38e01fcb3fc94.jpg);"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import titleComponent from '@/components/home/title.vue'
export default {
  name: 'train',
  components: {
    titleComponent,
  },
  data() {
    return {}
  },
  created() {
  },
  methods: {
    toTrainCategory() {
      this.$router.push({
        path: '/trainCategory',
        query: {
          pid: 1,
          welfareTrain: this.$route.query.welfareTrain
        }
      })
    },
    //是否登录
    toJump(val){
      if(this.$route.path !== val){
        if(val === "/trainOnline"){
          let schoolid = JSON.parse(localStorage.getItem("userInfo"));
          if(!schoolid.token){
            this.$store.commit("setLoginState", "login");
          }else{
            this.$router.push({
              path: val,
            });
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.train_page {
  width: 100%;
  margin: 100px auto 0;
  .navigate {
    width: 1270px;
    margin: 0 auto;
    margin-bottom: 90px;
    @include flex(row, space-between, center);
    .item {
      background: #cc1a30;
      width: 390px;
      height: 200px;
      border-radius: 010px;
      font-size: 24px;
      color: #ffffff;
      line-height: 24px;
      text-align: center;
      transition: all 0.3s;
    }
    .icon {
      display: block;
      height: 76px;
      margin-top: 40px;
      margin-bottom: 28px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 76px;
    }
    .base {
      background-image: url('../../assets/img/train/t_base.png');
    }
    .online {
      background-image: url('../../assets/img/train/t_online.png');
    }
    .special {
      background-image: url('../../assets/img/train/t_special.png');
    }
  }
  .top {
    height: 580px;
    background: #cc1a30;
    margin-bottom: 230px;
    position: relative;
    text-align: left;
    .info {
      padding-top: 180px;
      padding-left: 65px;
      div {
        font-size: 30px;
        color: #ffffff;
        line-height: 48px;
      }
      b {
        font-size: 40px;
        line-height: 40px;
        color: #ffffff;
        margin-bottom: 50px;
        display: block;
      }
      a {
        display: block;
        bottom: 40px;
        left: 50%;
        width: 180px;
        height: 46px;
        line-height: 42px;
        border: solid 2px #fff;
        font-size: 24px;
        color: #fff;
        margin-left: 20px;
        margin-top: 50px;
        background: url('../../assets/img/header/morewh.png') 140px center
          no-repeat;
        background-size: 24px;
        padding-left: 30px;
        box-sizing: border-box;
      }
    }
    .pic {
      position: absolute;
      left: 500px;
      top: 100px;
      right: 0;
      height: 615px;
      background-size: cover;
      background-position: center;
    }
  }
  .haspic {
    margin-bottom: 40px;
    display: flex;
    height: 470px;
    .imgs {
      display: flex;
      flex: 1;
      .pic {
        flex: 1;
        height: 4.7rem;
        background-size: cover;
        background-position: center;
      }
    }
    .info {
      width: 380px;
      background: #228bed;
      margin-left: 50px;
      text-align: center;
      color: #fff;
      padding-top: 160px;
      div {
        font-size: 24px;
        line-height: 30px;
      }
      b {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 40px;
        display: block;
      }
    }
    .info:nth-child(odd) {
      background: #cc1a30;
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }
}
</style>
